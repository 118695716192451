<template>
  <v-container>
    <page-title :title="title|t" />
    <eagle-form :form-key="formKey"></eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './productUnitFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'product-unit-form',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
    title() {
      return `product-unit.form.title.${this.formMode}`
    },
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async createApi(formData) {
      this.$root.gaLogEvent('供應商_點擊_建立單位')
      return await this.$api.collection.productUnitApi.create(this.providerId, formData)
    },
    async readApi(target) {
      return (await this.$store.getters[`list/product-unit-list/data`]).find(unit => {
        return unit.provider_id === this.providerId && unit.id === target
      })
    },
    async updateApi(target, formData) {
      this.$root.gaLogEvent('供應商_點擊_編輯單位')
      return await this.$api.collection.productUnitApi.update(this.providerId, {
        name: formData.name,
        id: target,
      })
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
