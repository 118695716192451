import Vue from 'vue';
import { EagleFormConfigInterface } from '@/components/form/types/form';
class formConfig {
  protected vm?: Vue;
  protected formKey?: string;

  _defaultData() {
    return {
      name: '',
    };
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm;
    this.formKey = formKey;

    return {
      from: () => [
        { label: 'page.product-unit', route: { name: 'product-unit' } },
      ],
      listRoute: () => ({ name: 'product-unit' }),
      afterCreateRoute: () => ({
        name: 'product-unit',
      }),
      pageTitle: (formMeta: any, formData: AnyObject) =>
        window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.product_unit',
      defaultData: this._defaultData,
      blocks: [
        {
          data: {
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              placeholder: 'product-unit.data.name.placeholder',
              hasChanged: ({
                originalData,
                data,
                bothEmpty,
              }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false;
                return originalData != data;
              },
            },
          },
        },
      ],
      dataAction: {
        delete: false,
        status: false,
        refresh: false,
      },
    };
  }
}

export default new formConfig();
